<template>
    <!-- ============================ Hero Banner  Start================================== -->
    <div class="" style="background:#1CA4D4; min-height:100vh">

        <div class="container">
            <div class="row align-items-center">

                <div class="col-lg-4 col-md-12 col-sm-12"></div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-2" >

                    <a :href="coreUrl.jobsUrl+'home' ">

                       <base-image style="max-width:150px"
                           imgName="white_logo.png"
                           imgGeo="logo"
                           imgAlt="logo"
                           class="mt-5 mb-2 center-image"
                        >
                        </base-image>

                    </a>

                    <div class="mb-4 text-center text-white">
                        Securely log into your account
                   </div>


                    <!-- Single Wrap -->
                    <base-card-response  :cardType=" successStatus[confirmData] " >

                        <template #header>Confirm account {{ confirmStatus?'success':'failure' }} </template>
                        <template #default>
                            <p>
                                {{ confirmMessage }}
                            </p>
                            <p>
                                <router-link :to=" isAuthenticated?'/dashboard':'/login' ">
                                    <u style="color:#fff"> {{ isAuthenticated?' Go to dashboard ':' Go to login ' }} </u>
                                </router-link>
                            </p>
                        </template>

                    </base-card-response>

                    <!-- Single Wrap End -->

                    <router-link to="/sign_up"  >
                        <small class="auth-extra-link">
                            <i class="bi bi-power"></i> Create account
                        </small>
                    </router-link>

                    <router-link to="/login" class="float-right">
                        <small class="auth-extra-link">
                            <i class="bi bi-lock"></i> {{ isAuthenticated?' Dashboard ':' Login ' }}
                        </small>
                    </router-link>

                </div>

                <div class="col-lg-4 col-md-12 col-sm-12"></div>

            </div>
        </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->

</template>

<script>
import { ref, inject } from 'vue';
import { useStore } from 'vuex';
// @ is an alias to /src

export default {
  name: 'AuthConfirmAccount',
  props: {
      confirmId:{
          type:String,
          default:"nothing"
      }
  },
  setup( props ){
      // get the global vuex store values
      const store = useStore();

      // get the axios object
      const axios = inject('axios');  // inject axios

      //get the urls
      const coreUrl = inject('coreUrl');

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",false);
      store.dispatch("globals/executeSetFooterMenu",false);

      const confirmData = ref("secondary")
      const confirmStatus = ref("")
      const confirmMessage = ref("")

      const successStatus = {
          new_confirmation:"success",
          existing_confirmation:"primary",
          failed_request:"secondary"
      }

      // use axios to get the data
      axios.get(coreUrl.backendUrl+'validate_confirm_account/'+props.confirmId,{withCredentials: true}).then((response) =>{
          // return the full token to the token variable
          confirmData.value = response.data.data;
          confirmStatus.value = response.data.status;
          confirmMessage.value = response.data.message;

      }).catch((error)=>{
          // if an error occours, do this
          console.log(error)
          confirmStatus.value = false;
          confirmMessage.value = "Your request could not be approved at the moment";
      })

      const isAuthenticated = ref(store.getters["auth/getAuthAuthentication"].isAuthenticated);

      return {
          coreUrl,
          isAuthenticated,
          successStatus,
          confirmData,
          confirmStatus,
          confirmMessage
      }


  }

};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.center-image{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

</style>
